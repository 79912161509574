import Axios from 'axios';
import appConfig from '../../helpers/config';
import { toast } from "react-toastify";
import { POP_UP_CLOSE_5000, SUCCESS_STATUS, SUCCESS_STATUS_ERROR } from '../../constants/global';
import { API_CUSTOM_MESSAGE } from '../../validations/validationErrors';
let store:any;

// const baseUrlApi = appConfig.apiEndPoint;
const baseUrlApiClient = appConfig.apiEndPointClient;
const baseUrlApiCompany = appConfig.apiEndPointCompany;
export const injectStore = (_store:any) => {
    store = _store
}

/**
 * Axios Interceptor
 * @param {*} config
 * @returns {*}
 */
function authRequestInterceptor(config: any) {
    const token = store?.getState()?.auth?.userInfo;
    const authToken = token === undefined || token === null ? "": token?.access_token;    
    // Dynamically set the baseURL based on whether the user is a company or client
    config.baseURL = store?.getState()?.auth?.userInfo?.company_login ? baseUrlApiCompany : baseUrlApiClient;
    if (token) {
        config.headers.authorization = `Bearer ${authToken}`;
    }
    // config.headers.Content-Type = 'application/json';
    return config;
}


/**
 * Axios Object to use 
 * @type {*}
 */
// export const axiosRequest = Axios.create({
//     // baseURL: baseUrlApi
//     baseURL: getBaseUrl()
// });
export const axiosRequest = Axios.create();

axiosRequest.interceptors.request.use(authRequestInterceptor);
axiosRequest.interceptors.response.use(
    (response) => {
        if(response.status === SUCCESS_STATUS) {
            
            console.log('response', response.status)
            const SUCCESS_MESSAGE = response.data.message;
            toast.success(SUCCESS_MESSAGE, { autoClose: POP_UP_CLOSE_5000 });
        }else if(response.status === SUCCESS_STATUS_ERROR){
            const SUCCESS_MESSAGE = response.data.message
            toast.error(SUCCESS_MESSAGE, { autoClose: POP_UP_CLOSE_5000 });
        }
        return response.data;
    },
    async (error) => {
        console.log("error", error)
        if (error.response.status === 401) {
            window.localStorage.setItem("checklogout", "forceLogout");
            window.dispatchEvent(new Event("storage"));
        }else{
            let ERROR_MESSAGE  = "";
            const errorRsp = error.response;

            const status = error && errorRsp ? errorRsp.status : "";
            if(error && errorRsp && errorRsp.data.msg){
                ERROR_MESSAGE = errorRsp.data.msg
            }
            if(error && errorRsp && errorRsp.data.message)
            {
                ERROR_MESSAGE = errorRsp.data.message
            }
            if (status !== SUCCESS_STATUS && ERROR_MESSAGE !== "") {
                toast.error(ERROR_MESSAGE,{autoClose:POP_UP_CLOSE_5000})        
            }else if (status !== SUCCESS_STATUS) {
                toast.error(API_CUSTOM_MESSAGE.SOMETHING_WRONG, { autoClose: POP_UP_CLOSE_5000 });
            }
            else if (status !== SUCCESS_STATUS && ERROR_MESSAGE === "") {
            toast.error(API_CUSTOM_MESSAGE.SOMETHING_WRONG, { autoClose: POP_UP_CLOSE_5000 });
            }


        }
        return Promise.reject(error);
    }
);
